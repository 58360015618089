.search-map {
  padding: 0;
  height: min(calc(90vh - 4.5rem), calc(100vh - 4.5rem - 150px));
  position:fixed;
  top: 3.3rem;
  width:100%;
  z-index: -1;
}

.search-list {
  margin-top: min(calc(90vh - 4.5rem), calc(100vh - 4.5rem - 150px));
  padding: 1.5rem 1.5rem;
  /*min-height: calc(100vh - 4.5rem);*/
}

@media only screen and (min-width: 768px) {
  .search-container{
    display: flex;
    flex-direction: row-reverse;
  }
  .search-map {
    padding: 0;
    height: calc(100vh - 4.5rem);
    position: sticky;
    flex-grow: 1;
    flex-basis: 100px;
    z-index:unset;
  }
  .search-list {
    margin-top: 0;
    flex-grow: 1;
    flex-basis: 100px;
    min-width: 40rem;
    max-width: 45rem;
  }

}


.pin {
  display: flex;
  align-items: center;
  width: 2rem;
  height: 2rem;
  margin-top: -1rem;
  margin-left: -1rem;
  color: var(--main-blue);
  background: white;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 2px 2px #ccc;
}

.pagination {
  margin-top: 2rem;
}


.pin-icon {
  font-size: 1.5rem;
}

.pin-text {
  font-size: 1.3em;
  display: none;
}

.pin-name-tag {
  position: absolute;
  bottom: calc(100% + 1rem);
  background: white;
  /*width: 10rem;*/
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.preview-card {
  position: absolute;
  bottom: calc(100% + 1rem + 0.5rem);
  background: white;
  width: 12rem;
  border-radius: 0.5rem;
  max-width: initial;
  display: flex;
  flex-direction: column;
}

.preview-card-content {
  padding: 0.5rem;
}

.preview-card-image {
  background: #cccccc;
  background-size: cover;
  height: 6rem;
}

.hidden {
  display: none;
}

.hovered {
  z-index: 101;
}

.is-foreground {
  z-index: 10000;
}
.is-foreground-2 {
  z-index: 20000;
}