.hidden {
  display: none;
}

.first-column {
  min-width: 200px;
  width: 30%;
}

.carousel .slide .legend {
  border-radius: 0;
}

.image-placeholder {
  position: relative;
  padding: 5rem 1rem;
  background: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  grid-column: 1/-1;
  grid-row: 1/-1;
}

.location-description {
  width: 100%;
}

.image-slide {
  object-fit: contain;
  max-height: 14rem;
  max-width: 14rem;
}

.slider {
  display: flex;
  align-items: center;
}

.imagePreviewContainer {
  position: relative;
  width: 100%;
  min-height: 20rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  border-radius: 4px;
  overflow: hidden;
}

.total-images-1 {
  grid-template-areas: 
    "area1 area1 area1 area1"
    "area1 area1 area1 area1";
}
.total-images-2 {
  grid-template-areas: 
    "area1 area1 area2 area2"
    "area1 area1 area2 area2";
}
.total-images-3 {
  grid-template-areas: 
    "area1 area1 area2 area2"
    "area1 area1 area3 area3";
}
.total-images-4 {
  grid-template-areas: 
    "area1 area1 area2 area2"
    "area3 area3 area4 area4";
}
.total-images-5 {
  grid-template-areas: 
    "area1 area1 area2 area3"
    "area1 area1 area4 area5";
}

.previewImage {
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}

.previewImage1 {
  grid-area: area1;
}
.previewImage2 {
  grid-area: area2;
}
.previewImage3 {
  grid-area: area3;
}
.previewImage4 {
  grid-area: area4;
}
.previewImage5 {
  grid-area: area5;
}

.images-toolbar-button {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.images-toolbar-button > *:not(:last-child) {
  margin-right: 0.5rem; 
} 

.add-images-button {
}

.flex-distribute {
  display: flex;
  flex-direction: column;
}

.overview-symbol{
  font-size: 1.5rem;
  text-align: center;
  width: 3rem;
  flex-shrink: 0;
}

.overview-row {
  display: inline-flex;
  align-items: center;
}

.overview-row + .overview-row {
  margin-top: 1rem;
}

.upload-image-preview {
  max-height: 60vh;
  display: block;
  object-fit: contain;
}

.image-upload-modal-content {
  display: flex;
}

.upload-image-preview-container {
  flex-grow: 1;
  overflow: auto;
  margin: 0 1rem;
}

.location-map-environments {
  height: 25rem;
  margin: 1 0rem;
}

.image-gallery-right {
    top: 50%;
    position: fixed;
    right: 1rem;
    z-index: 1;
}

.image-gallery-left {
  top: 50%;
  position: fixed;
  left: 1rem;
  z-index: 1;
}

.fixed-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.gallery-image {
  max-height: 80vh;
  max-width: 80vw;
  object-fit: contain;
}

.is-borderless {
  border: 0 !important;
}

.number-range {
  align-items: center;
}

input[type='checkbox'] {
  width: 1rem;
  height: 1rem;
}

.entry-contact-area {
  padding-top: 1rem;
}

@media only screen and (min-width: 768px) {
  .entry-contact-area {
    position: sticky;
    top: 0px;
    margin-top: -2rem;
    padding-top: 5rem;
    flex-grow: 1;
    align-self: flex-start;
  }
  .main-entry-area {
    display: flex;
    flex-direction: row-reverse;
  }

  .flex-distribute {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: initial;
  }

  .inline-input {
    display: inline-block;
    width: 5rem;
    margin: 0 1rem;
  }

  .overview-row {
    padding: 1rem 0;
    width: 50%;
    flex-grow:1;
  }

  .enforce-full-width {
    width: 100% !important;
  }

  .overview-row + .overview-row {
    margin-top: 0;
  }
}

.entry-content-area {
  flex-grow: 2;
}
