
.location-media-container {
    height: 12rem;
    width: initial;
    flex-shrink: 0;
    background: #cccccc;
    background-size: cover;
    background-position: center;
  }
  
  .location-info-container{
    flex-grow: 1;
  }
  
  .location-list-entry {
    display: flex;
    flex-direction: column;
    min-height: 9rem;
    cursor: pointer;
  }
  
  .location-list-entry + .location-list-entry {
    margin-top: 1rem;
  }

  .location-entry-cost-section {
    text-align: right;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .location-entry-content {
    display: flex;
  }

  .location-entry-tags {
    display: block;
  }

@media only screen and (min-width: 768px) {
  .location-list-entry {
    flex-direction: row;
    max-height: 20vh;
  }

  .location-info-container{
    flex-basis: 400px;
  }

  .location-media-container {
    height: initial;
    width: 12rem;
  }

  .location-entry-tags {
    display: none;
  }
  .location-entry-cost-section {
    flex-grow: 1;
  }
}


@media only screen and (min-width: 1024px) {
  .location-entry-tags {
    display: block;
  }
}