.dropdown {
    width: 100%;
}

.dropdown-trigger {
    width: 100%;
}

.dropdown-menu {
    width: 100%;
}