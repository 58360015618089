.calendar {
    display: flex;
    flex-direction: column;
}
.calendar-head{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.calendar-month-name {
    min-width: 10rem;
    text-align: center;
}

.month {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
}
.calendar-weekdays {
    font-weight: bold;
}
.month-element {
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.month-day{
    border: 1px solid transparent;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.month-element {
    cursor: pointer;
}

.month-element:hover {
    /*border-radius: 100%;*/
    border: 1px solid black;
    background-color: hsl(206, 70%, 96%);
    
}
.month-day-past>.month-day {
    text-decoration: line-through;
}
.month-day-past {
    cursor: default;
}

.month-day-past:hover>.month-day {
    border: 0px solid black;
    background-color: transparent;
}

.month-day-selected {
    background-color: hsl(171, 100%, 41%) !important;
    color: white;
}
.month-day-range {
    background-color: hsl(171, 100%, 96%) !important;
}