.main-content-back {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-size: cover;
  background-position: center;
  filter: contrast(0.5) brightness(1.5);
}

.main-search-form {
  overflow: unset;
}