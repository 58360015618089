html {
  scroll-behavior: smooth;
}

.big-header {
  font-size: 40px;
}

.main-content {
  margin-top: 4.5rem;
  min-height: calc(100vh - 4.5rem);
  display: flex;
  flex-direction: column;
}

div.formBox {
    max-width:500px;
    margin: auto;
}

nav {
  box-shadow: 0 0.5em 0.5em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
}


.footer {
  z-index: 1;
}

.is-link {
  cursor: pointer;
}