.main-content {
  min-height: calc(100vh - 4.5rem);
}

.birthday-container {
  width: 100%;
  display: flex;
}

.birthday-item {

}

.captcha {
  width: 160px;
  height:75px;
  border: 0;
}