body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html {
  scroll-behavior: smooth;
}

.big-header {
  font-size: 40px;
}

.main-content {
  margin-top: 4.5rem;
  min-height: calc(100vh - 4.5rem);
  display: flex;
  flex-direction: column;
}

div.formBox {
    max-width:500px;
    margin: auto;
}

nav {
  box-shadow: 0 0.5em 0.5em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
}


.footer {
  z-index: 1;
}

.is-link {
  cursor: pointer;
}
.dropdown {
    width: 100%;
}

.dropdown-trigger {
    width: 100%;
}

.dropdown-menu {
    width: 100%;
}
.main-content-back {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-size: cover;
  background-position: center;
  -webkit-filter: contrast(0.5) brightness(1.5);
          filter: contrast(0.5) brightness(1.5);
}

.main-search-form {
  overflow: unset;
}
.calendar {
    display: flex;
    flex-direction: column;
}
.calendar-head{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.calendar-month-name {
    min-width: 10rem;
    text-align: center;
}

.month {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
}
.calendar-weekdays {
    font-weight: bold;
}
.month-element {
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.month-day{
    border: 1px solid transparent;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.month-element {
    cursor: pointer;
}

.month-element:hover {
    /*border-radius: 100%;*/
    border: 1px solid black;
    background-color: hsl(206, 70%, 96%);
    
}
.month-day-past>.month-day {
    text-decoration: line-through;
}
.month-day-past {
    cursor: default;
}

.month-day-past:hover>.month-day {
    border: 0px solid black;
    background-color: transparent;
}

.month-day-selected {
    background-color: hsl(171, 100%, 41%) !important;
    color: white;
}
.month-day-range {
    background-color: hsl(171, 100%, 96%) !important;
}


.location-media-container {
    height: 12rem;
    width: initial;
    flex-shrink: 0;
    background: #cccccc;
    background-size: cover;
    background-position: center;
  }
  
  .location-info-container{
    flex-grow: 1;
  }
  
  .location-list-entry {
    display: flex;
    flex-direction: column;
    min-height: 9rem;
    cursor: pointer;
  }
  
  .location-list-entry + .location-list-entry {
    margin-top: 1rem;
  }

  .location-entry-cost-section {
    text-align: right;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .location-entry-content {
    display: flex;
  }

  .location-entry-tags {
    display: block;
  }

@media only screen and (min-width: 768px) {
  .location-list-entry {
    flex-direction: row;
    max-height: 20vh;
  }

  .location-info-container{
    flex-basis: 400px;
  }

  .location-media-container {
    height: initial;
    width: 12rem;
  }

  .location-entry-tags {
    display: none;
  }
  .location-entry-cost-section {
    flex-grow: 1;
  }
}


@media only screen and (min-width: 1024px) {
  .location-entry-tags {
    display: block;
  }
}
.search-map {
  padding: 0;
  height: min(calc(90vh - 4.5rem), calc(100vh - 4.5rem - 150px));
  position:fixed;
  top: 3.3rem;
  width:100%;
  z-index: -1;
}

.search-list {
  margin-top: min(calc(90vh - 4.5rem), calc(100vh - 4.5rem - 150px));
  padding: 1.5rem 1.5rem;
  /*min-height: calc(100vh - 4.5rem);*/
}

@media only screen and (min-width: 768px) {
  .search-container{
    display: flex;
    flex-direction: row-reverse;
  }
  .search-map {
    padding: 0;
    height: calc(100vh - 4.5rem);
    position: -webkit-sticky;
    position: sticky;
    flex-grow: 1;
    flex-basis: 100px;
    z-index:unset;
  }
  .search-list {
    margin-top: 0;
    flex-grow: 1;
    flex-basis: 100px;
    min-width: 40rem;
    max-width: 45rem;
  }

}


.pin {
  display: flex;
  align-items: center;
  width: 2rem;
  height: 2rem;
  margin-top: -1rem;
  margin-left: -1rem;
  color: var(--main-blue);
  background: white;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 2px 2px #ccc;
}

.pagination {
  margin-top: 2rem;
}


.pin-icon {
  font-size: 1.5rem;
}

.pin-text {
  font-size: 1.3em;
  display: none;
}

.pin-name-tag {
  position: absolute;
  bottom: calc(100% + 1rem);
  background: white;
  /*width: 10rem;*/
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.preview-card {
  position: absolute;
  bottom: calc(100% + 1rem + 0.5rem);
  background: white;
  width: 12rem;
  border-radius: 0.5rem;
  max-width: initial;
  display: flex;
  flex-direction: column;
}

.preview-card-content {
  padding: 0.5rem;
}

.preview-card-image {
  background: #cccccc;
  background-size: cover;
  height: 6rem;
}

.hidden {
  display: none;
}

.hovered {
  z-index: 101;
}

.is-foreground {
  z-index: 10000;
}
.is-foreground-2 {
  z-index: 20000;
}
.hidden {
  display: none;
}

.first-column {
  min-width: 200px;
  width: 30%;
}

.carousel .slide .legend {
  border-radius: 0;
}

.image-placeholder {
  position: relative;
  padding: 5rem 1rem;
  background: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  grid-column: 1/-1;
  grid-row: 1/-1;
}

.location-description {
  width: 100%;
}

.image-slide {
  object-fit: contain;
  max-height: 14rem;
  max-width: 14rem;
}

.slider {
  display: flex;
  align-items: center;
}

.imagePreviewContainer {
  position: relative;
  width: 100%;
  min-height: 20rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
  border-radius: 4px;
  overflow: hidden;
}

.total-images-1 {
  grid-template-areas: 
    "area1 area1 area1 area1"
    "area1 area1 area1 area1";
}
.total-images-2 {
  grid-template-areas: 
    "area1 area1 area2 area2"
    "area1 area1 area2 area2";
}
.total-images-3 {
  grid-template-areas: 
    "area1 area1 area2 area2"
    "area1 area1 area3 area3";
}
.total-images-4 {
  grid-template-areas: 
    "area1 area1 area2 area2"
    "area3 area3 area4 area4";
}
.total-images-5 {
  grid-template-areas: 
    "area1 area1 area2 area3"
    "area1 area1 area4 area5";
}

.previewImage {
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}

.previewImage1 {
  grid-area: area1;
}
.previewImage2 {
  grid-area: area2;
}
.previewImage3 {
  grid-area: area3;
}
.previewImage4 {
  grid-area: area4;
}
.previewImage5 {
  grid-area: area5;
}

.images-toolbar-button {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.images-toolbar-button > *:not(:last-child) {
  margin-right: 0.5rem; 
} 

.add-images-button {
}

.flex-distribute {
  display: flex;
  flex-direction: column;
}

.overview-symbol{
  font-size: 1.5rem;
  text-align: center;
  width: 3rem;
  flex-shrink: 0;
}

.overview-row {
  display: inline-flex;
  align-items: center;
}

.overview-row + .overview-row {
  margin-top: 1rem;
}

.upload-image-preview {
  max-height: 60vh;
  display: block;
  object-fit: contain;
}

.image-upload-modal-content {
  display: flex;
}

.upload-image-preview-container {
  flex-grow: 1;
  overflow: auto;
  margin: 0 1rem;
}

.location-map-environments {
  height: 25rem;
  margin: 1 0rem;
}

.image-gallery-right {
    top: 50%;
    position: fixed;
    right: 1rem;
    z-index: 1;
}

.image-gallery-left {
  top: 50%;
  position: fixed;
  left: 1rem;
  z-index: 1;
}

.fixed-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.gallery-image {
  max-height: 80vh;
  max-width: 80vw;
  object-fit: contain;
}

.is-borderless {
  border: 0 !important;
}

.number-range {
  align-items: center;
}

input[type='checkbox'] {
  width: 1rem;
  height: 1rem;
}

.entry-contact-area {
  padding-top: 1rem;
}

@media only screen and (min-width: 768px) {
  .entry-contact-area {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    margin-top: -2rem;
    padding-top: 5rem;
    flex-grow: 1;
    align-self: flex-start;
  }
  .main-entry-area {
    display: flex;
    flex-direction: row-reverse;
  }

  .flex-distribute {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: initial;
  }

  .inline-input {
    display: inline-block;
    width: 5rem;
    margin: 0 1rem;
  }

  .overview-row {
    padding: 1rem 0;
    width: 50%;
    flex-grow:1;
  }

  .enforce-full-width {
    width: 100% !important;
  }

  .overview-row + .overview-row {
    margin-top: 0;
  }
}

.entry-content-area {
  flex-grow: 2;
}

.main-content {
  min-height: calc(100vh - 4.5rem);
}

.birthday-container {
  width: 100%;
  display: flex;
}

.birthday-item {

}

.captcha {
  width: 160px;
  height:75px;
  border: 0;
}
.is-equal-distributed{
  flex-basis: 0;
  flex-grow: 1;
}
